import React, { useContext } from "react";

export const ConfigContext = React.createContext<WebConfig>({
  graphqlAPI: "",
  appUrl: "",
  communityUrl: "",
  rudderstackURL: "",
  rudderstackWriteKeyWebApp: "",
  revision: "",
  oauthAppleRedirectUrl: "",
  oauthGoogleRedirectUrl: "",
  oauthLinkedinRedirectUrl: "",
  intercomAppId: "",
  API: "",
  environment: "",
  growthbookKey: "",
  googleApiKey: "",
});
export interface WebConfig {
  graphqlAPI: string;
  appUrl: string;
  communityUrl: string;
  rudderstackURL: string;
  rudderstackWriteKeyWebApp: string;
  revision: string;
  oauthAppleRedirectUrl: string;
  oauthGoogleRedirectUrl: string;
  oauthLinkedinRedirectUrl: string;
  intercomAppId: string;
  API: string;
  environment: string;
  growthbookKey: string;
  googleApiKey: string;
}

export const useConfig = () => useContext(ConfigContext);
